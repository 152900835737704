// Here you can add other styles
.c-sidebar {
  background: #191919;
}

.c-sidebar-brand {
  background: #000000;
}

.logo-container {
  padding: 15px;
}

.logo-container img {
  max-width: 120px;
}

.c-sidebar-minimizer {
  display: none;
}

.react-datetime-picker {
  width: 100%;
}

.no-record-text {
  font-weight: 400;
  margin-bottom: 0;
}

.react-datetime-picker__wrapper {
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid;
  color: #768192;
  border-color: #d8dbe0 !important;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.table .thead-light th {
  background-color: #f3f3f3;
}

.card-title {
  margin-top: 5px;
  margin-bottom: 0px;
}

.card-no-records-label {
  margin-bottom: 0px;
}

.container-main-action {
  background: #ffffff;
  margin-top: -32px;
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: 32px;
  border-bottom: 1px solid #d8dbe0;
}

.contact-avatar {
  width: 100%;
}

h5 {
  font-size: 1.04375rem;
}

.nav-item {
  border-bottom: 0px solid #d8dbe0;
}

.nav-link {
  padding: 0.5rem 0rem;
}

/* Lead: Wizard */
.progressbar {
  counter-reset: step;
  display: flex;
  padding-inline-start: 0px;
  margin-block-end: 0px;
}

.progressbar li {
  list-style: none;
  display: inline-block;
  width: 100%;
  position: relative;
  text-align: center;
  cursor: pointer;
}

.progressbar li:before {
  content: "";
  counter-increment: step;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #ddd;
  border-radius: 100%;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  background-color: #fff;
  position: relative;
  z-index: 2;
}

.progressbar li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  background-color: #ddd;
  top: 12px;
  left: -50%;
  z-index: 1;
}

.progressbar li:first-child:after {
  content: none;
}

.progressbar li.active {
  color: #39f;
}

.progressbar li.active:before {
  border-color: #39f;
  background: #39f;
}

.progressbar li.active:after {
  background-color: #39f;
}

/* React Calendar Styles */
#scheduler-container {
  background: #000000;
  min-height: 100vh;
}

.web-consultation-pick-time,
.pick-time-title-container p {
  color: #ffffff;
}

.pick-time-title-container hr {
  border-color: #67003d;
}

div.react-calendar {
  width: 100%;
  background: rgb(0, 14, 25);
  background: linear-gradient(
    90deg,
    rgba(0, 14, 25, 1) 0%,
    rgba(0, 84, 149, 1) 100%
  );
  border: 0;
  border-radius: 15px;
  padding: 15px;
}

.book-discovery-schedule {
  width: 100%;
  background: rgb(0, 14, 25);
  background: linear-gradient(
    90deg,
    rgba(0, 14, 25, 1) 0%,
    rgba(0, 84, 149, 1) 100%
  );
  border: 0;
  border-radius: 15px;
  padding: 15px;
}

.book-discovery-schedule label {
  color: #fff;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: transparent !important;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  color: #fff !important;
}

.react-calendar__month-view__days__day--weekend abbr {
  color: #d10000 !important;
}

.react-calendar__month-view__days__day--neighboringMonth abbr {
  color: #757575 !important;
}

.react-calendar__tile:disabled abbr {
  color: #777777 !important;
}

.react-calendar__navigation button[disabled] {
  background-color: transparent !important;
}

.react-calendar__tile:disabled {
  background-color: transparent !important;
}

button.react-calendar__tile--active {
  background: transparent;
  color: #ffffff;
  padding: 0;
}

.react-calendar__tile {
  min-height: 50px;
}

button.react-calendar__tile--active abbr {
  transition: 0.5s;
  padding: 5px;
  background: #ce007b;
  border-radius: 100px;
  display: inline-block;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 26px;
  color: #fff !important;
}

.react-calendar__tile--now {
  background: transparent !important;
}

.react-calendar__tile:enabled:focus {
  background-color: transparent !important;
}

.react-calendar__tile:enabled:hover {
  background-color: #0e4a79 !important;
}

[class^="bg-white"] {
  color: inherit;
}

button.react-calendar__tile--active:enabled:hover,
button.react-calendar__tile--active:enabled:focus {
  background: transparent;
}

button.react-calendar__tile--active:enabled:focus {
  background: transparent;
}

.sched-button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid transparent;
  margin-top: 15px;
  margin-bottom: 15px;
  min-width: 150px;
  font-size: 16px;
  border-radius: 10px;
  font-weight: 600;
  color: #000;
}

.sched-button.btn:hover {
  text-decoration: underline;
  font-weight: 600;
  border: 1px solid #000;
  background: #fff;
}

.sched-button.btn:last-child {
  margin-bottom: 0;
}

.pick-time-title-container {
  padding-bottom: 20px;
}

#schedule-container .container-fluid {
  height: 100%;
}

#schedule-container .container-fluid .fade.show {
  height: 100%;
}

#schedule-container .container-fluid .fade.show > .row {
  height: 100%;
}

#schedule-container .c-main {
  padding-bottom: 32px;
}

.bg-black {
  background: #000000;
}

#web-consultation-sidebar {
  padding: 15px 20px 25px 20px;
}

#scheduler-container {
  padding-top: 32px;
  padding-bottom: 32px;
}

.web-consultation-border-pink {
  border-color: #67003d;
}

#schedule-time-container {
  padding: 20px 0;
  background: rgb(9, 9, 9);
  background: linear-gradient(
    90deg,
    rgb(26, 26, 26) 0%,
    rgba(47, 47, 47, 1) 100%
  );
  color: #fff;
  border-radius: 15px;
  min-height: 416px;
}

#web-consultation-sidebar .logo-container {
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 20px;
}

/* Calendar Confirmation */
.time-confirmation {
  background: #1f1f1f;
  position: absolute;
  right: 460px;
  width: 100%;
  max-width: 320px;
  padding: 15px;
  z-index: 2;
}

.close-confirmation {
  font-size: 22px;
  position: absolute;
  right: 12px;
  top: 2px;
  font-weight: 600;
}

.close-confirmation:hover {
  cursor: pointer;
}

.selected-time-title {
  font-weight: 600;
  font-size: 14px;
}

.time-and-date,
.timezone {
  font-size: 14px;
  margin-bottom: 0;
}

.confirmation-btn {
  margin-top: 15px;
  background: #ebedef;
  font-weight: 600;
  border-radius: 10px;
  padding: 8px 15px;
}

.calendar-clear {
  margin-right: 10px;
}

.calendar-submit,
.calendar-submit:hover {
  background: #ce007b;
  color: #fff;
}

#scheduler-container .container-fluid {
  max-width: 1366px;
  margin-left: auto;
  margin-right: auto;
}

/* Login Page */
.input-group {
  transition: 0.2s;
}

#email-field-container .input-group,
#password-field-container .input-group {
  border: 2px solid transparent;
}

#email-field-container.error .input-group,
#password-field-container.error .input-group {
  border: 2px solid red;
  border-radius: 5px;
}

.password-field-error {
  margin-top: -23px;
}

.email-field-error {
  margin-top: -15px;
}

.incorrect-credentials {
  padding: 10px;
  transition: 0.2s;
}

#multistep-form-error {
  padding: 10px 15px;
  border-radius: 5px;
  transition: 0.3s;
}

/* Proposal Form */

.dynamic-field {
  max-width: 92.2%;
  display: inline-block;
  margin-right: 1%;
}

.btn-dynamic {
  color: #fff;
  font-size: 18px;
  padding: 0px 7px;
  margin-right: 5px;
  border-radius: 3px;
}

.add-field {
  border: 2px solid #0d86ff;
  background: #0d86ff;
  margin-right: 0;
  position: absolute;
  right: 0;
  top: -10px;
}

.remove-field {
  border: 2px solid #de2727;
  background: #de2727;
  margin-right: 0;
}

.phase-container label {
  display: block;
}

.phase-container .one-third {
  width: 29.6%;
  margin-right: 1.5%;
  display: inline-block;
}

.phase-container .dynamic-field {
  max-width: 100%;
}

.phase-container[data-multiple="false"] .one-third {
  width: 32%;
}

.phase-container[data-multiple="false"] .one-third:last-child {
  margin-right: 0
}

.tech-container[data-multiple="false"] .dynamic-field {
  width: 100%;
  max-width: 100%;
}

.phase-container, .tech-container {
  margin-bottom: 20px;
}

#proposal-form h6 {
  margin-bottom: 15px;
}

#proposal-form .form-group {
  position: relative;
  margin-bottom: 25px;
}

#proposal-form hr {
  margin-bottom: 25px;
}

#proposal-form .bg-danger {
  margin-bottom: 20px;
  font-size: 16px;
  padding: 5px;
  border-radius: 5px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

#addticket-btn {
  display: block;
  text-align: right;
  display: block;
  margin-left: auto;
}

.ticket-desc {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

#tickets-container hr:last-child {
  display: none;
}

#container-comments hr:last-child {
  display: none;
}

.ticket-row:hover {
  cursor: pointer;
}

.ticket-number {
  margin-top :10px;
}

.ticket-row:hover {
  cursor: pointer;
}

.ticket-title, .comment-title {
  display: inline-block;
  margin-bottom: 0;
}

.ticket-date {
  font-size: 12px;
  margin-bottom: 0;
}

.comment-message {
  margin-bottom: 0;
}

.ticket-icon, .comment-icon {
  max-width: 35px;
  margin-right: 0px;
}

.ticket-row .col-sm-1, .comment-row .col-sm-1 {
  padding-right: 0;
  flex: 0 0 8%;
}

.ticket-row .col-sm-11, .comment-row .col-sm-8 {
  padding-left: 0;
}

.payment-input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #3c4b64;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  margin-left: 5px;
  margin-right: 5px;
}

.authorization-link, .authorization-link:hover {
  text-decoration: none !important;
}

.settings-tab-container p:hover {
  cursor: pointer;
}

.settings-tab-container p.active {
  color: #0080ff;
}

.payment-input:focus-visible {
  outline: none !important;
}

.payment-methods-container input {
  margin-right: 0;
}

.payment-method-label {
  min-width: 155px;
  display: inline-block;
}

.payment-methods-container label {
  vertical-align: bottom;
  margin-bottom: 10px;
  display: block;
}

.payment-methods-container {
  padding: 20px;
  min-height: 350px;
  background: #e8e8e8;
  border-radius: 10px;
}

.payment-alert-info {
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
}

.payment-alert {
  line-height: 2.4;
}

#extra_charge {
  max-width: 65px;
}

/*#payment-page .container {
  max-width: 900px;
}*/

.pl-30 {
  padding-left: 30px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

/*#payment-page p, #payment-page label, #payment-page input {
  font-size: 19px;
}*/

#recurring_amount_to_charge, #onetime_amount_to_charge {
  max-width: 115px
}

.payment-methods-container input[type="text"], .payment-methods-container input[type="number"] {
  width: 100%;
  margin-left: 0;
  margin-right: 0;

}

.signatureCanvas {
  margin-right: 25px;
  width: 100%;
  background: #e8e8e8;

}

#payment-page #date {
  width: 100%;
  margin-left: 0;
  margin-top: 15px;
}

.signature-date {
  display: inline-block;
}

.printed-name {
  display: inline-block;
  margin-top: 20px;
  margin-right: 10px;
  vertical-align: bottom;
}

#payment-page #printed_name {
  margin-bottom: 0;
  width: 100%;
  margin-left: 0;
  margin-top: 15px;
}

#billing-container label {
  margin-bottom: -5px;
  vertical-align: bottom;
  min-width: 135px;
}

#billing-container input {
  margin-bottom: 0;
  width: 100%;
}

/* Settings */
.tab-header.card-header {
  padding-bottom: 0;
  padding-left: 15px;
  padding-right: 15px;
  background: #e6e6e6;
  border-bottom: 0;
}

.tab-header.card-header button {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}


/* Multi Step Form Animation */

.slide-left {
	-webkit-animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-right {
	-webkit-animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/**
 * ----------------------------------------
 * animation slide-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

/**
 * ----------------------------------------
 * animation slide-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }
}



@media only screen and (min-width: 981px) {
  #onetime_description_of_goods, #recurring_description_of_goods {
    min-width: 250px;
  }

  .payment-methods-container {
    min-height: 451px;
  }

  #billing-container input {
    max-width: 100%;
    margin-left: 0;
    margin-top: 15px;
  }

  #billing-container input {
    margin-right: 0;
  }
  .payment-methods-container #exp_month, .payment-methods-container #exp_year {
    min-width: auto;
  }
}

@media only screen and (max-width:1134px) {
    .col-xxl, .col-xxl-auto, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      flex: 0 0 100%;
      max-width: 100%;
  }

  #billing-container input, .payment-methods-container input[type="text"], .payment-methods-container input[type="number"] {
    width: 100% !important;
    max-width: 100% !important;
  }

  .payment-methods-container .payment-method-input.half {
    max-width: 100% !important;
  }

}

@media only screen and (max-width: 981px) {
  .pick-time-title-container,
  .calendar-container,
  .time-container {
    padding-left: 35px;
    padding-right: 35px;
  }

  .calendar-container {
    margin-bottom: 35px;
  }

  #scheduler-container {
    padding-top: 0;
    padding-bottom: 50px;
  }

  .time-confirmation {
    right: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
    left: 0;
  }

  .web-consultation-title,
  .web-consultation-pick-time {
    font-size: 20px;
  }

  #scheduler-container .c-main {
    padding-top: 0;
  }

  .sched-button.btn:last-child {
    margin-bottom: 20px;
  }

  #schedule-time-container {
    padding-bottom: 0;
  }
}
